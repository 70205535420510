// function to generate a GUID by concatenating random hexadecimal strings.

export const generateGUID = (): string => {
    const s4 = (): string => {
        return ((1 + crypto.getRandomValues(new Uint16Array(1))[0]) * 0x10000)
            .toString(16)
            .substring(1);
    };

    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};