import { mergeStyleSets } from "@fluentui/react";

export const commonCardStyles = mergeStyleSets({
    botCard: {
        flexGrow: 1,
        minHeight: "170px",
        padding: "8px 8px 8px 8px",
        margin: "10px 0px 10px 0px",
        width: "92.5%",
        maxWidth: "none",
        borderRadius: "24px",
        border: "1px solid #F0F0F0",
        background: "#FFF",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
   
    },

    card: {
    //    display: 'flex',
    //    flexDirection: 'column',
    flexGrow: 1,
    minHeight: "411px",
    padding: "8px 8px 8px 8px",
    margin: "0 0px 20px 0",
    width: "318px",
    maxWidth: "none",
    borderRadius: "24px",
    border: "1px solid #F0F0F0",
    background: "#FFF",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        "@media (min-width: 1470px) and (max-width: 1599px)": {
            width: "300px",
        },
    "@media (max-width: 1470px) and (min-width: 1310px)": {
      width: "260px",
    },

    "@media (max-width: 1309px)": {
      width: "240px",
    },
        "@media (max-width: 1199px)": {
            width: "318px",
        },
    "@media (max-width: 900px) and (min-width: 768px)": {
      width: "300px",
    },

    "@media (max-width: 768px)": {
      width: "auto",
        },
        ":hover::after": {         
            borderRadius: "24px !important",         
        },
        ":focus::after": {
            borderRadius: "24px !important",
        },
  },
  cylinder: {
    marginTop: "7px",
    marginLeft: "15px",
    width: "auto",
    height: "18px",
    borderRadius: "50px",
    color: "white",
    fontFamily: "Segoe UI",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "16px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  rightAlign: {
    display: "flex",
    alignItems: "center",
  },
  contentTypeRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  contentType: {
    margin: "3px 5px",
  },
    cardDescription: {
      display: "flex",
      minHeight: "155px",
      justifyContent: "space-between",
    height:"52%"
  },
  cardActivity: {
    fontWeight: "700",
  },
});
