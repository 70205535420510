import { FC, useEffect, useState } from "react";
import PageTabNavigator, { sections } from "./NavBar/PageTabNavigator";
import { Element } from "react-scroll";
import Banner from "./Banner/Banner";
import AIAssistant from "./AI Assistant/AI Assistant";
import NavigationMenu from "./NavBar/NavigationMenu.tsx";
import ChatBotPanel from "./ChatBot/ChatBotPanel";
import { LearnByTopic } from "./LearnByTopic/LearnByTopic";
import { LearnByRole } from "./LearnByRole/LearnByRole";
import Skills from "./Skills/Skills";
import SuccessStories from "./SuccessStories/SuccessStories";
import BannerMobile from "./Banner/BannerMobile";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { fetchTranslations } from '../i18n';
import { withTranslation } from 'react-i18next';
import { mergeStyleSets } from "@fluentui/react";


const components = [
    <LearnByTopic />,
    <LearnByRole />,
    <Skills />,
    <SuccessStories />,
];

interface LandingPageProps {
    showPanel: boolean;
    togglePanel: () => void;
    closePanel: () => void;
    botAction: string;
    initialText: string;
    onButtonClick: (action: string, text: string) => void;
    setBotAction: (action: string) => void;
    setinitialText: (text: string) => void;

}

const LandingPage: FC<LandingPageProps> = ({
    showPanel,
    togglePanel,
    closePanel, botAction,
    onButtonClick,
    initialText,
    setBotAction,
    setinitialText
}) => {
    const styles = mergeStyleSets({
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000, // Ensure it is above other content
        }
    })
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingLocalization, setIsLoadingLocalization] = useState(true);
    const { t, i18n } = useTranslation();
    const { lang } = useParams<{ lang?: string }>();
    const navigate = useNavigate();
    const location = useLocation();
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        localStorage.clear();
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    useEffect(() => {
        const changeLanguage = async (newLang: string) => {
            setIsLoadingLocalization(true);
            await fetchTranslations(newLang);
            await i18n.changeLanguage(newLang);
            setIsLoadingLocalization(false);
        };
        const detectedLang = i18n.language || 'en-US'; // Get detected language or fall back to 'en-us'
        changeLanguage(lang || detectedLang);
    }, [lang, i18n, location, navigate]);
    //const handleButtonClick = (action: string) => {
    //    setBotAction(action);
    //    togglePanel();
    //};

    if (isLoadingLocalization)
        return <div><Spinner className={styles.overlay} size={SpinnerSize.large}></Spinner></div>

    return (
        <>
            <BannerMobile onButtonClick={onButtonClick} isLoading={isLoading} />
            <Banner onButtonClick={onButtonClick} isLoading={isLoading} />
            <AIAssistant onButtonClick={onButtonClick} isLoading={isLoading} />
            <PageTabNavigator />
            <NavigationMenu />
            <ChatBotPanel
                isOpen={showPanel}
                togglePanel={togglePanel}
                botAction={botAction}
                initialText={initialText}
                setBotAction={setBotAction}
                setinitialText={setinitialText}
                isLoadingData={isLoading}
                setIsLoading={setIsLoading}
            />
            {sections.map((section, index) => (
                <Element key={section.id} id={section.id} name={section.id} className="section">
                    {components[index]}
                </Element>
            ))}
        </>
    );

};

export default withTranslation()(LandingPage);
