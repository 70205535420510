import React from "react";

interface WarningsProps {
    text: string;
}

const Warnings: React.FC<WarningsProps> = ({ text }) => {
    return (
        <div className="message-container">
            <div className="new-session">{text}</div>
        </div>
    );
}

export default Warnings;
