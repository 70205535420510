import React from "react";
import { Stack, Text, DefaultButton } from "@fluentui/react";

import "./BannerStyles.scss";
import banner from "./BannerData.json";
import { commonStyles } from "../CommonStyles/CommonStyles";
import { useTranslation } from "react-i18next";
interface BannerMobileProps {
    isLoading: boolean;
    onButtonClick: (action: string, text: string) => void;
}

const BannerMobile: React.FC<BannerMobileProps> = ({ onButtonClick, isLoading }) => {
    const images = (require as any).context("./assets", true);
    const bannerMob = images("./Wave.png");
    const { t, i18n } = useTranslation();

    return (
        <>
            <Stack className="bannerImgMob">
                <Stack>
                    <img src={bannerMob} alt="banner" />
                </Stack>
                <Stack className="banner-section">
                    <Stack className="banner-container">
                        <Stack className="banner-background">
                            {banner &&
                                banner.BannerData.map((item: any, index: number) => {                                   
                                    const mobileImageSrc = images(item.mobileImageUrl);
                                    return (
                                        <div
                                            key={index}
                                            className="image-container"
                                            style={{
                                                background: ` url(${mobileImageSrc}) no-repeat center center `,
                                                backgroundSize: "cover",
                                            }}
                                        >

                                            <Stack className={commonStyles.container}>
                                                <Stack
                                                    horizontal
                                                    horizontalAlign="end"
                                                    className="banner-div"
                                                >
                                                    <Stack className="text-container">
                                                        <Text variant="large" className="text heading">
                                                        {t('Banner.BannerData.0.heading')} 
                                                        </Text>
                                                        <Text variant="xxLarge" className="text main-heading">
                                                        {t('Banner.BannerData.0.mainHeading')} 
                                                        </Text>
                                                        <Text variant="mediumPlus" className="text description">
                                                        {t('Banner.BannerData.0.description')} 
                                                        </Text>
                                                        {item.buttonText.trim() !== "" && (
                                                            <div className="button-container">
                                                                <DefaultButton disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }}
                                                                    className="banner-button"
                                                                    onClick={() => onButtonClick("SkillsPlan", "")}
                                                                >
                                                                    {t('Banner.BannerData.0.buttonText')} 
                                                                </DefaultButton>
                                                            </div>
                                                        )}
                                                    </Stack>
                                                    {/*<div className="people-section">*/}
                                                    {/*    <img src={peopleimg} alt="logo" />*/}
                                                    {/*</div>*/}
                                                </Stack>
                                            </Stack>
                                        </div>
                                    );
                                })}
                        </Stack>
                    </Stack>
                </Stack>

            </Stack>
          
        </>
    );
};

export default BannerMobile;
