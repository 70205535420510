import { ReactNode, useEffect, useRef, useState } from "react";
import "./ChatbotStyles.scss";
import { Icon, Stack, Text, mergeStyleSets } from "@fluentui/react";
import { Button } from "@fluentui/react-components";
import { Prompt } from "../../Types/PromptType";
import Warnings from "./Warnings";
import { ResponseWithFeedback } from "../../Types/FeedbackType";
import { useTranslation } from "react-i18next";

const styles = mergeStyleSets({
    link: {
        color: "#0074D3", textDecoration: "underline", fontSize: "14px", lineHeight: "23px", fontWeight: 400, marginLeft: "3px", marginRight: "3px"
    }
});


interface MessagesProps {
    botAction?: string;
  messages: ReactNode[];
  isLoading: boolean;
    userPrompt: Prompt;
    stopResponding: boolean;
    stopBotResponding: () => void;
}

const Messages: React.FC<MessagesProps> = ({
    botAction,
  messages,
  isLoading,
  userPrompt,
    stopResponding,
    stopBotResponding
}) => {
    const re = useRef<HTMLDivElement>(null);
    const [response, setResponse] = useState<ResponseWithFeedback>({ id: "", response: "", feedback: "", timeStamp: "" });
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (isLoading) {
            re.current?.scrollIntoView({ block: "end", behavior: "auto" });
        }
    }, [isLoading]);

    function stopAssistantResponding(): void {
        stopBotResponding();

    }

    // Function to format the current timestamp
    const formatTimestamp = () => {
        const now = new Date();
        return now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

  return (
      <>
          {userPrompt.isNewSession?  (
              <Warnings key={1} text={t('ChatBot.NewChat')} />
          ) : ""}
                
          <div className="messages" >            
              {messages}
             
          </div>
             
          {isLoading && !stopResponding ? (
              <div>
                  <div className="accept-text" >
                      <div className="text" >  {t('ChatBot.GeneratingResponse')}</div>  
                      <div id={"re"} ref={re} />
                  </div>

              </div>

          ) : ('')}
       
    </>
  );
};

export default Messages;
