import React, { useEffect, useState } from 'react';
import './LanguageList.scss';
import axiosClient from "../utilities/HttpClient";


interface SupportedLanguage {
    Locale: string;
    EnglishName: string;
    LocalizedName: string;
}
const LanguageList: React.FC = () => {
    const [supportedLanguages, setSupportedLanguages] = useState<SupportedLanguage[]>([]);
    useEffect(() => {
        fetchSupportedLanguages();
    }, []);
  
    const fetchSupportedLanguages = async (): Promise<void> => {
        try {
            if (!localStorage.getItem('supportedLanguages')) {
                const response = await axiosClient.get<{ SupportedLanguages: SupportedLanguage[] }>('/BlobConfig/SupportedLanguage');
                const languages = response.data.SupportedLanguages;
                setSupportedLanguages(languages);
                localStorage.setItem('supportedLanguages', JSON.stringify(supportedLanguages));
            } else {
                const cachedLanguages = JSON.parse(localStorage.getItem('supportedLanguages') || '[]');
                setSupportedLanguages(cachedLanguages);
            }
        } catch (error) {
            console.error('Failed to fetch supported languages:', error);
            if (window.location.pathname !== '/error') {
                window.location.href = '/error';
            }
        }
    };

    return (
        <div style={{ padding: '20px', background: '#ffff' }}>
            <h1 style={{ marginBottom: '20px', marginLeft: '4rem', fontSize: '1.5rem', fontWeight: '600', lineHeight: '1.2' }}>
                This site in other countries/regions:
            </h1>
            <div className="language-grid">
                {supportedLanguages.map((language, index) => (
                    <a
                        className="language-item"
                        key={index}
                        href={language.Locale}
                        rel="noopener noreferrer"
                    >
                        {language.LocalizedName || language.EnglishName} {/* Display localized or English name */}
                    </a>
                ))}
            </div>
        </div>
    );
};

export default LanguageList;
