import {
    Text,
    Stack,
    Icon,
    mergeStyleSets,
    DocumentCardPreview,
    DocumentCard,
    TooltipHost,
} from "@fluentui/react";
import { commonCardStyles } from "./CardStyles";
import { getCardPreviewProps } from "./CardHelpers";
import { LearningType } from "../../Types/LearningType";
import { Link } from "@fluentui/react-components";

export type ICardProps = {
    cardData: LearningType;
};

const blogStyles = {
    spotlightBlog: {
        backgroundColor: "#0010A4",
    },
    contentText: {
        marginRight: "10px",
    },
    blogActivity: {
        top: "20px",
        position: "relative",
    },
    icon: {
        fontSize:"9px",
        color: "white",
        marginRight: "5px",
        marginLeft: "5px",
        position: "relative",
        top: "4px",
        left: "6px",
    },
    subTitle: {
        display: "-webkit-box",
        overflow: "hidden",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: "5",
        textOverflow: "ellipsis",
        fontSize: "14px",
        color: "#0E1726",
        margin: "6px 10px",
        fontWeigt: 400,

    },
    title: {
        display: "-webkit-box",
        overflow: "hidden",
        WebkitBoxOrient: "vertical",
           WebkitLineClamp: "2",
        textOverflow: "ellipsis",
        fontSize: "18px",
        color: "#0E1726",
        fontWeight: "700",
        margin: "6px 8px",
        fontWeigt: 600,
    },
    linkButton: {
        position: "relative",
        left:"10px",    
        width: "30px !important",
        height: "30px !important",
        borderRadius: "6px",
        backgroundColor: "#0078D4"   
    },
    linkFocus: {          
        ':focus-visible': {               
          outline: "none !important",                   
          textDecorationStyle: "solid !important",
          textDecorationLine: "underline !important"                      
        }     
    },
    linkText: {
    fontSize: "14px",
    fontWeight: 600,
        top: "5px",
        position: "relative",
        left: "17px",
        whiteSpace: "nowrap",
      lineHeight: "23px",
       letterSpacing: "-0.03em",
        textAlign: "left",
        color: "#686868",
        overflow: "hidden",      
        textOverflow: "ellipsis"
    },
    linkCategory: {
        left: "0px !important",
        marginLeft: "7px !important"
    },
    cardTitle: {
        display: "-webkit-box",
        overflow: "hidden",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: "2",
        textOverflow:"ellipsis",
        fontSize: "13px",
        color: "#0078D4",
        fontWeight: "700",
        padding: "5px 8px",
        paddingTop:"12px",
        fontWeigt: 600,
    },
    linkColor: {
        color: "#0067B8 !important"
    },
    buttonWidth: {
        width: "140px",
        "@media (max-width: 1470px)": {
            width: "100px"
        },
        "@media (max-width: 1310px)": {
            width: "88px"
        },
        "@media (max-width: 1198px)": {
            width: "140px",
        },
        "@media (max-width: 768px)": {
            width: "88px"
        },
        "@media (max-width: 600px)": {
            width: "200px",
        },
        "@media (max-width: 400px)": {
            width: "130px",
        },
    }
};

const styles = mergeStyleSets(blogStyles, commonCardStyles);

const LearningCard: React.FC<ICardProps> = (props) => {
    const { cardData } = props;
    const data = cardData as LearningType;
    const images = (require as any).context("../../assets", true);
    const linkimg = images("./LinkedIn.png");
    const msLearnImg = images("./mslogo.png");

    const handleCardClick = () => {
        window.open(data.learnMoreUrl, "_blank", "noopener,noreferrer");
    };
    return (

        <DocumentCard className={styles.card} onClick={handleCardClick} tabIndex={0}>
            <DocumentCardPreview
                styles={{ root: { borderRadius: "24px"} }}
                {...getCardPreviewProps(data)}
            />
            <Stack className={styles.cardDescription}>

                {/*<TitleForCard cardData={data} />*/}
                <TooltipHost content={data.heading}>
                    <Text className={styles.cardTitle} title={data.heading}> {data.heading}</Text>
                </TooltipHost>
                <TooltipHost content={data.title}>
                    <Text className={styles.title}>{data.title}</Text>
                </TooltipHost>
                <TooltipHost content={data.description}>
                    <Text className={styles.subTitle} >{data.description}</Text>
                </TooltipHost>


                <Stack horizontal horizontalAlign="space-between" style={{margin:"6px 0px"} }>
                    <Stack.Item >
                        <Link href={data.learnMoreUrl} target="_blank" className={styles.linkFocus}>
                            <Stack horizontal >
                        <div className={styles.linkButton}>
                        <Icon iconName="ChevronRight" className={styles.icon} />
                                </div>                             
                                <span title={data.buttonText} className={`${styles.linkText} ${styles.linkColor} ${styles.buttonWidth}` } >
                                {data.buttonText }
                                    </span>                             
                    </Stack>          
                    </Link>
                    </Stack.Item>
                    <Stack.Item >
                        <Stack horizontal style={{ position: "relative", right: "15px" }} >
                     
                            {data.linkCategory === 'LinkedIn' && (
                            <img src={linkimg} alt="logo" style={{
                                 width: "23.75px",height: "24px"
                               }} />
                            )}
                     
                     
                            {data.linkCategory === 'Microsoft' && (
                            <img src={msLearnImg} alt="logo" style={{  width: "23.75px", height: "24px" }} />
                            )}
                            <span className={`${styles.linkText} ${styles.linkCategory}`} >
                            {data.linkCategory}
                            </span>
                      
                    </Stack> 
                  
                    </Stack.Item>
                       
                </Stack>
            </Stack>
        </DocumentCard>
    );
};

export default LearningCard;
