import i18n from "i18next";



export const getLanguageCodeFromUrl = (url) => {
    try {        
        const parsedUrl = new URL(url);
        const pathname = parsedUrl.pathname.split('/'); // Split by slashes
        // Assuming the language code is the first segment after the domain
        const languageCode = pathname[1]; // Get the first path segment
        if (/^[a-z]{2}-[A-Z]{2}$/.test(languageCode)) {
          return languageCode;
      } else {        
        const detectedLang = i18n.language || 'en-us';
          return detectedLang; // Default to 'en-US' if not found or invalid
      }
    } catch (error) {
        console.error("Invalid URL:", error);
        return 'en-us';
    }
  };