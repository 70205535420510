import { useEffect } from "react";
import "./MsUHFStyles.scss";
import { LoadCss } from "./helperFunctions";
import { useUHF } from "../../hooks/useHooks";

const MsHeader: React.FC = () => {
    const { data } = useUHF();

    useEffect(() => {
        async function parseXmlData(xmlData: any) {
            const tempHtml =
                xmlData.getElementsByTagName("headerHtml")[0].childNodes[0];
            const tempCss =
                xmlData.getElementsByTagName("cssIncludes")[0].childNodes[0];

            const tempJs =
                xmlData.getElementsByTagName("javascriptIncludes")[0].childNodes[0];

            const headerSection = document.getElementById("header-container");

            headerSection && (await LoadCss(tempCss.wholeText, headerSection));

            const srcRegex = /<script src="([^"]*)"><\/script>/;
            const match = tempJs.wholeText.match(srcRegex);
            if (match) {
                const script = document.createElement("script");
                script.src = match[1];
                script.type = "text/javascript";
                if (headerSection) {
                    headerSection.appendChild(script);
                }
            }
            const header = document.getElementById("header");
            if (header) header.innerHTML = tempHtml.wholeText;
        }
        data && parseXmlData(data);
    }, [data]);

    return (
        <div id="header-container" className="header">
            <div id="header"></div>
        </div>
    );
};

export default MsHeader;
