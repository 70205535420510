import React from "react";

interface UserMessageProps {
    text: string; 
    timeStamp: string;
}

const UserMessage: React.FC<UserMessageProps> = ({ text, timeStamp }) => {

    // Function to format the current timestamp
    const formatTimestamp = () => {
        const now = new Date();
        return now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    return (
        <>
            <div className="message-container">
                <div className="user-message">{text}</div>
            </div>
            <div className="timestamp-user">
                {timeStamp}
            </div>
        </>

    );
}

export default UserMessage;
