import React, { useEffect, useState } from 'react';
import { mergeStyleSets } from "@fluentui/react";
import { Button } from "@fluentui/react-components";
import { useTranslation, withTranslation } from 'react-i18next';
import { fetchTranslations } from '../i18n';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";



const ErrorPage: React.FC = () => {
    const { t, i18n } = useTranslation();
    const [isLoadingLocalization, setIsLoadingLocalization] = useState(true);
    const { lang } = useParams<{ lang?: string }>();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const changeLanguage = async (newLang: string) => {
            try {
                setIsLoadingLocalization(true);
                await fetchTranslations(newLang);
                await i18n.changeLanguage(newLang);
            } catch (error) {
                console.error('Failed to change language:', error);
            } finally {
                setIsLoadingLocalization(false);
            }
        };
        const detectedLang = i18n.language || 'en-US'; // Get detected language or fall back to 'en-us'
        changeLanguage(lang || detectedLang);
    }, [lang, i18n, location, navigate]);

    const handleClick = () => {
        navigate('/'); // Navigate to the home page
    };


    if (isLoadingLocalization)
        return <div><Spinner className={styles.overlay} size={SpinnerSize.large}></Spinner></div>


    return (
        <div className={styles.container}>
            <h1>{t('Error', 'Error')}</h1>
            <p>{t('ErrorMessage', 'Something went wrong. Please try again later.')}</p>
            <Button appearance="secondary" onClick={handleClick}>{t('ErrorHomeButtonText', 'Go to home page')}</Button>
        </div>
    );
};

const styles = mergeStyleSets({
    container: {
        background: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '60vh', // Full viewport height
        textAlign: 'center',
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000, // Ensure it is above other content
    }
});

export default withTranslation()(ErrorPage);
