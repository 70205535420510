import React from "react";
import { Stack, Text, DefaultButton } from "@fluentui/react";
import "./BannerStyles.scss";
import banner from "./BannerData.json";
import { commonStyles } from "../CommonStyles/CommonStyles";
import { useTranslation } from "react-i18next";
interface BannerProps {
    isLoading: boolean;
    onButtonClick: (action: string, text: string) => void;
}

const Banner: React.FC<BannerProps> = ({ onButtonClick, isLoading }) => {
    const images = (require as any).context("./assets", true);
    const { t, i18n } = useTranslation();
    return (
        <Stack className="bannerImg">
            <Stack className="banner-section">
                <Stack className="banner-container">
                    <Stack className="banner-background">
                        {banner &&
                            banner.BannerData.map((item: any, index: number) => {
                                const imagesrc = images(item.imageUrl);
                                return (
                                    <div
                                        key={index}
                                        className="image-container"
                                        style={{
                                            background: ` url(${imagesrc}) no-repeat center center `,
                                            backgroundSize: "cover",
                                        }}
                                    >

                                        <Stack className={commonStyles.container}>
                                            <Stack
                                                horizontal
                                                horizontalAlign="end"
                                                className="banner-div"
                                            >
                                                <Stack className="text-container">
                                                    <Text variant="large" className="text heading">
                                                        {t('Banner.BannerData.0.heading','AI SKILLS NAVIGATOR')}
                                                    </Text>
                                                    <Text variant="xxLarge" className="text main-heading">
                                                        {t('Banner.BannerData.0.mainHeading','Chart your course. Learn AI today.')}
                                                    </Text>
                                                    <Text variant="mediumPlus" className="text description">
                                                        {t('Banner.BannerData.0.description','Unlock new opportunities with AI skills that are in demand now and in the future. Use AI to find the learning path that\'s right for you.')}
                                                    </Text>
                                                    {item.buttonText.trim() !== "" && (
                                                        <div className="button-container">
                                                            <DefaultButton disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }}
                                                                className="banner-button"
                                                                onClick={() => onButtonClick("SkillsPlan", "")}
                                                            >
                                                                {t('Banner.BannerData.0.buttonText','Find your skills path')}
                                                            </DefaultButton>
                                                        </div>
                                                    )}
                                                </Stack>
                                                {/*<div className="people-section">*/}
                                                {/*    <img src={peopleimg} alt="logo" />*/}
                                                {/*</div>*/}
                                            </Stack>
                                        </Stack>
                                    </div>
                                );
                            })}
                    </Stack>
                </Stack>
            </Stack>

        </Stack>

    );
};

export default Banner;
