
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import './i18n';

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

initializeIcons();

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
