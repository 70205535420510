import {
    Text,
    Stack,
    Icon,
    mergeStyleSets,
    DocumentCardPreview,
    DocumentCard,
} from "@fluentui/react";
import { commonCardStyles } from "./CardStyles";
import { getCardPreviewProps } from "./CardHelpers";
import { LearningType } from "../../Types/LearningType";
import { Link } from "@fluentui/react-components";

export type ICardProps = {
    cardData: LearningType;
};

const blogStyles = {
    spotlightBlog: {
        backgroundColor: "#0010A4",
    },
    contentText: {
        marginRight: "10px",
    },
    blogActivity: {
        top: "20px",
        position: "relative",
    },
    icon: {
        fontSize: "9px",
        color: "white",
        marginRight: "5px",
        marginLeft: "5px",
        position: "relative",
        top: "-1px",
        left: "1px",
    },
    subTitle: {
        display: "-webkit-box",
        overflow: "hidden",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: "3",
        textOverflow: "ellipsis",
        fontSize: "14px",
        color: "#0E1726",
        padding: "0px 10px",
        fontWeigt: 400,

    },
    title: {
        display: "-webkit-box",
        overflow: "hidden",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: "2",
        textOverflow: "ellipsis",
        fontSize: "16px",
        color: "#0E1726",
        fontWeight: "600",
        padding: "6px  8px 0px 8px",
        fontWeigt: 600,
    },
    linkButton: {
        position: "relative",
        left: "10px",
        width: "20px !important",
        height: "20px !important",
        borderRadius: "6px",
        backgroundColor: "#0078D4"
    },
    linkText: {
        fontSize: "14px",
        fontWeight: 600,
      //  top: "5px",
        position: "relative",
        left: "17px",
        whiteSpace: "nowrap",
        lineHeight: "23px",
        letterSpacing: "-0.03em",
        textAlign: "left",
        color: "#686868",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    linkCategory: {
        left: "5px !important",
      //  marginLeft: "7px !important"
    },
    cardTitle: {
        display: "-webkit-box",
        overflow: "hidden",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: "2",
        textOverflow: "ellipsis",
        fontSize: "13px",
        color: "#0078D4",
        fontWeight: "700",
        padding: "6px 8px",
        fontWeigt: 600,
    },
    linkColor: {
        color: "#004275"
    },
    botRes: {
        '@media (max-width: 400px)': {
            
                display: 'block',
            
        },
    },
    botlearn: {
        '@media (max-width: 400px)': {

            top: "5px",
            left: "10px"

        },
    },
    
};

const styles = mergeStyleSets(blogStyles, commonCardStyles);

const BotLearningCard: React.FC<ICardProps> = (props) => {
    const { cardData } = props;
    const data = cardData as LearningType;
    const images = (require as any).context("../../assets", true);
    const linkimg = images("./LinkedIn.png");
    const msLearnImg = images("./mslogo.png");
    return (
        <DocumentCard className={styles.botCard} >
            <Stack horizontal>
                <DocumentCardPreview
                    styles={{ root: { borderRadius: "24px", height:"169px", width:"125px !important", flexShrink:0 } }}
                    {...getCardPreviewProps(data)}
                />
                <Stack className={styles.cardDescription}>
                    <Stack
                        horizontal
                        verticalAlign="center"
                        className={styles.contentTypeRow}
                    >
                        <Stack
                            horizontal
                            horizontalAlign="end"
                            verticalAlign="center"
                            className={styles.rightAlign}
                        >
                        </Stack>
                    </Stack>
                    {/*<TitleForCard cardData={data} />*/}
                   
                    <Text className={styles.title} title={data.title}>{data.title}</Text>
                    <Text className={styles.subTitle} title={data.description}>{data.description}</Text>


                    <Stack horizontal horizontalAlign="space-between" className={styles.botRes}>
                        <Stack.Item >
                            <Link href={data.learnMoreUrl} target="_blank">
                                <Stack horizontal >
                                    <div className={styles.linkButton}>
                                        <Icon iconName="ChevronRight" className={styles.icon} />
                                    </div>
                                    <span title={data.buttonText}  className={`${styles.linkText} ${styles.linkColor}`} style={{ width: "110px" }}>
                                        {data.buttonText}
                                    </span>
                                </Stack>
                            </Link>
                        </Stack.Item>
                        <Stack.Item >
                            <Stack horizontal style={{ position: "relative", right: "10px" }} className={styles.botlearn } >

                                {data.linkCategory === 'LinkedIn' && (
                                    <img src={linkimg} alt="logo" style={{
                                        width: "16px", height: "16px"
                                    }} />
                                )}


                                {data.linkCategory === 'Microsoft' && (
                                    <img src={msLearnImg} alt="logo" style={{ width: "16px", height: "16px" }} />
                                )}
                                <span className={`${styles.linkText} ${styles.linkCategory}`} style={{}}>
                                    {data.linkCategory}
                                </span>

                            </Stack>

                        </Stack.Item>

                    </Stack>
                </Stack>
        </Stack>
       
           
        </DocumentCard>
    );
};

export default BotLearningCard;
