import { Stack, mergeStyleSets, Text, Icon } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { commonStyles } from "../CommonStyles/CommonStyles";
import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    AccordionToggleEventHandler,
    Link,

} from "@fluentui/react-components";
import { useSuccessStories } from "../../hooks/useHooks";
import "./SuccessStories.scss";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useNavigate } from 'react-router-dom';

const SuccessStories: React.FC = () => {
    const { data, isLoading, error } = useSuccessStories();
    const modifiedData = data?.map((item: any) => ({
        ...item,
        curatedPosition: item.curatedPosition.toString(),
    }));
    const images = (require as any).context("../../assets", true);
    const skillspeople = images("./people.png");
    const [currentImage, setCurrentImage] = React.useState<string>(skillspeople);
    const [imageChanged, setImageChanged] = useState<boolean>(false);
    const navigate = useNavigate();

    const styles = mergeStyleSets({
        storiesContainer: {
            backgroundColor: "#fff",
            padding: "50px 0px",
            "@media (max-width: 768px)": {
                marginTop: "0px",
            },
            "@media (max-width: 1024px)": {
                padding: "48px 0px",
            },
        },
        link: {
            color: "#0074D3 !important", textDecoration: "none", fontSize: "16px"
        },
        storiesHeading: {
            fontSize: "32px",
            fontWeight: 600,
            lineHeight: "40px",
            textAlign: "left"

        },
        storiesDescription: {
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "21.28px",
            textAlign: "left",
            marginTop: "20px"
        },
        details: {
            backgroundColor: "white",
            padding: "30px 0px",
            borderRadius: "24px",
            "@media (max-width: 1024px)": {
                padding: "0px",
            },
        },
        storiesButton: {
            backgroundColor: "#0078D4",
            color: "white !important",
            borderRadius: "8px",
            marginTop: "20px",
            padding: "20px"
        },
        storiesImg: {
            animation: "slideIn 0.5s ease-in -out",
            borderRadius: "16px",
            "@media (max-width: 1400px)": {
                width: "500px !important"
            },
            "@media (max-width: 1024px)": {
                display: "none"
            },
        },
        storiesImgMob: {
            padding: "1rem 0rem",
            animation: "slideIn 0.5s ease-in -out",
            borderRadius: "16px",
            "@media (max-width: 1400px)": {
                width: "100% !important"
            },
            "@media (max-width: 1024px)": {
                display: "flex"
            },
            "@media (min-width: 1025px)": {
                display: "none"
            },
        },
        detailsDiv: {
            padding: "0rem 2rem 0 2rem",
            "@media (max-width: 1400px)": {
                padding: "0rem 2rem 0 2rem",
            },
            "@media (max-width: 1024px)": {
                padding: "0rem !important",
            },
        },


    });

    useEffect(() => {
        if (error) {
            // Navigate to the error page
            navigate('/error');
        }
    }, [error, navigate]);

    useEffect(() => {
        if (imageChanged) {
            const timer = setTimeout(() => setImageChanged(false), 500);
            return () => clearTimeout(timer);
        }
    }, [imageChanged]);
    const [openItems, setOpenItems] = React.useState(["1"]);
    const [activeItem, setActiveItem] = React.useState<string | null>("1");
    const { t } = useTranslation();
    const handleToggle: AccordionToggleEventHandler<string> = (event, data) => {
        setOpenItems(data.openItems);
        setActiveItem(data.value);
        const selectedItem = modifiedData?.find((item: any) => item.curatedPosition === data.value);
        if (selectedItem) {
            setCurrentImage(images(`./${selectedItem.imageUrl}`));
            setImageChanged(true);
        }
    };


    const heading = t('SuccessStories.heading','null')?.trim();
    const subHeading = t('SuccessStories.subHeading','null')?.trim();
  
    // Check for empty, null, or "null" values
    if (!heading || heading === "null" || !subHeading || subHeading === "null"|| !data?.length) {
        return null;
    }

    return (
        <Stack className={styles.storiesContainer} >
            <Stack className={commonStyles.container}>
                <Stack horizontalAlign="center">
                    <Text variant="large" className={styles.storiesHeading}>
                        {t('SuccessStories.heading')}
                    </Text>
                    <Text variant="medium" className={styles.storiesDescription} style={{ textAlign: "center" }}>
                        {t('SuccessStories.subHeading')}
                    </Text>
                </Stack>
                {(isLoading) && <Spinner size={SpinnerSize.large} />}
                {data?.length && !isLoading && (<Stack horizontal className={styles.details}>
                    <Stack className={imageChanged ? "effect" : ""} >
                        <img src={currentImage} alt="stories" className={styles.storiesImg} />
                    </Stack>
                    <Stack className={styles.detailsDiv}>
                        <Accordion openItems={openItems} onToggle={handleToggle} >
                            {modifiedData && modifiedData.map((item: any, index: any) => (
                                <AccordionItem key={index} value={item.curatedPosition} className={`accordion-item ${activeItem === item.curatedPosition ? 'active' : ''}`}>
                                    <div className="item">
                                        <AccordionHeader expandIconPosition="end" className="storyHeading">{item.title}</AccordionHeader>
                                        <AccordionPanel className="storyDescription">
                                            <div style={{ paddingBottom: "10px" }}>{item.description}</div>
                                            <div className="link-div">
                                                <Link href={item.learnMoreUrl} target="_blank" className="story-link"> {item.buttonText}
                                                    <Icon iconName="ChevronRight" style={{ position: "relative", top: "0px", left: "4px" }} className="story-link" /></Link>
                                            </div>
                                            <Stack className={imageChanged ? "effect" : ""} >
                                                <img src={currentImage} alt="stories" className={styles.storiesImgMob} />
                                            </Stack>
                                        </AccordionPanel>

                                    </div>

                                </AccordionItem>
                            ))}
                        </Accordion>
                    </Stack>
                </Stack>
                )}
            </Stack>
        </Stack>
    );
};

export default SuccessStories;
