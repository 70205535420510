import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import axiosClient from "./utilities/HttpClient";

interface SupportedLanguage {
  Locale: string;
  EnglishName: string;
  LocalizedName: string;
}

let supportedLanguages: SupportedLanguage[] = []; 

const fetchSupportedLanguages = async (): Promise<void> => {
  try {
    if (!localStorage.getItem('supportedLanguages')) {
      const response = await axiosClient.get<{ SupportedLanguages: SupportedLanguage[] }>('/BlobConfig/SupportedLanguage');
      supportedLanguages = response.data.SupportedLanguages;
      localStorage.setItem('supportedLanguages', JSON.stringify(supportedLanguages));
    } else {
      supportedLanguages = JSON.parse(localStorage.getItem('supportedLanguages') || '[]');
    }
  } catch (error) {
    console.error('Failed to fetch supported languages:', error);
    if (window.location.pathname !== '/error') {
      window.location.href = '/error';
    }
  }
};

// Function to convert languages to standardized i18next format 
const convertToI18nextLang = (lang: string): string => {
  const parts = lang.split('-');
  if (parts.length === 2) {
    return `${parts[0].toLowerCase()}-${parts[1].toUpperCase()}`;
  }
  return lang;
};

const fetchTranslations = async (curLanguage: string): Promise<void> => {
  await fetchSupportedLanguages();
  if (!supportedLanguages) {
    console.error('Supported languages not found.');
    return;
  }

  const i18nextLang = convertToI18nextLang(curLanguage);
  const langPrefix = curLanguage.split('-')[0];
  const queryString = window.location.search;

  // Find matching languages based on prefix
  const matchingLanguages = supportedLanguages.filter(lang => lang.Locale.startsWith(langPrefix));

  if (matchingLanguages.length > 0) {
    const exactMatch = matchingLanguages.find(lang => convertToI18nextLang(lang.Locale) === i18nextLang);

    if (exactMatch) {
      const redirectLanguage = exactMatch.Locale;
      if (window.location.pathname !== `/${redirectLanguage}`) {
        window.location.href = `/${redirectLanguage}${queryString}`; 
        return;
      }
    } else {
      const redirectLanguage = matchingLanguages[0].Locale; 

      if (window.location.pathname !== `/${redirectLanguage}`) {
        window.location.href = `/${redirectLanguage}${queryString}`;
        return;
      }
    }
  } else if (window.location.pathname !== '/en-us' && window.location.pathname !=='/error' && window.location.pathname !=='/locale' ) {
    // Fallback if no matches found
    window.location.href = `/en-us${queryString}`;
    return;
  }

  try {
    const response = await axiosClient.get(`/BlobConfig/pageContent/${i18nextLang}`);
    const translations = await response.data;
    i18n.addResourceBundle(i18nextLang, 'translation', translations);
    await i18n.changeLanguage(i18nextLang);
  } catch (err) {
    console.error(`Failed to fetch translations for ${i18nextLang}: ${err}`);
    if (window.location.pathname !== '/error') {
      window.location.href = '/error';
    }
  }
};


i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['navigator', 'querystring', 'cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
    },
    fallbackLng: 'en-US',
    returnObjects: true,
    initImmediate: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
  });

export { fetchTranslations };
export default i18n;
