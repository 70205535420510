import axiosClient from "../utilities/HttpClient";
import { getLanguageCodeFromUrl } from "../utilities/LocalizationHelper";

export const getSpotlightInfo = async (messageIds: Array<string>) => {
    const cachedData = getCachedData("spotlight");
    if (cachedData) return cachedData;
    try {
        const res = await axiosClient.get("LandingPage/getSpotlightInfo", {
            params: { messageIds: messageIds.join(",") },
        });
        const data = res.data;
        data && setCachedData("spotlight", data);
        return res.data;
    } catch (error) {
        throw new Error("Failed to fetch data");
    }
};

export const getLatestBlogs = async () => {
    const cachedData = getCachedData("latestBlogs");
    if (cachedData) return cachedData;
    try {
        const res = await axiosClient.get("LandingPage/getLatestBlogs");
        const data = res.data;
        data && setCachedData("latestBlogs", data);
        return data;
    } catch (error) {
        throw new Error("Failed to fetch data");
    }
};

export const getLatestEvents = async () => {
    const cachedData = getCachedData("latestEvents");
    if (cachedData) return cachedData;
    try {
        const res = await axiosClient.get("landingPage/getLatestEvents");
        const data = res.data;
        data && setCachedData("latestEvents", data);
        return data;
    } catch (error) {
        throw new Error("Failed to fetch data");
    }
};

export const getDiscussionCategories = async () => {
    const cachedData = getCachedData("discussionCategories");
    if (cachedData) return cachedData;
    try {
        const result = await axiosClient.get("LandingPage/discussionCategories");
        const data = result.data;
        data && setCachedData("discussionCategories", data);
        return data;
    } catch (error) {
        throw new Error("Failed to fetch data");
    }
};

export const getDiscussionDetails = async (
    boardId: string,
    sortParam: string
) => {
    try {
        const result = await axiosClient.get("LandingPage/discussionDetails", {
            params: { boardId, sortParam },
        });
        const data = result.data;
        return data;
    } catch (error) {
        throw new Error("Failed to fetch data");
    }
};

export const getUHF = async () => {
    try {
        const result = await fetch(
            "https://uhf.microsoft.com/en-us/shell/xml/MSNonProfitCommunity?headerid=MSNonProfitCommunityHeader&footerid=MSNonProfitCommunityFooter"
        );
        const data = await result.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(data, "text/xml");
        return doc;
    } catch (error: any) {
        throw new Error("Failed to fetch data");
    }
};

const getCachedData = (key: string) => {
    const cachedData = localStorage.getItem(key);
    if (cachedData) {
        const { data, timestamp } = JSON.parse(cachedData);
        const currentTime = new Date().getTime();
        const cacheDuration = 24 * 60 * 60 * 1000;

        if (currentTime - timestamp < cacheDuration) {
            return data;
        } else {
            localStorage.removeItem(key);
        }
    }

    return null;
};

const setCachedData = (key: string, data: any) => {
    const timestamp = new Date().getTime();
    const cacheData = JSON.stringify({ data, timestamp });
    localStorage.setItem(key, cacheData);
};
export const getLearnByTopic = async () => {
    const cachedData = getCachedData("LearnByTopic");
    const languageCode = getLanguageCodeFromUrl(window.location.href);
    if (cachedData) return cachedData;
    try {
        const res = await axiosClient.get(`landingPage/getLearnByTopic/${languageCode}`); // Pass the language code in the URL
        const data = res.data;
        data && setCachedData("LearnByTopic", data);
        return data;
    } catch (error) {
        throw new Error("Failed to fetch data");
    }
};

export const getLearnByRole = async () => {
    const cachedData = getCachedData("LearnByRole");
    const languageCode = getLanguageCodeFromUrl(window.location.href);
    if (cachedData) return cachedData;
    try {
        const res = await axiosClient.get(`landingPage/getLearnByRole/${languageCode}`);
        const data = res.data;
        data && setCachedData("LearnByRole", data);
        return data;
    } catch (error) {
        throw new Error("Failed to fetch data");
    }
};
export const getSuccessStories = async () => {
    const cachedData = getCachedData("SuccessStories");
    const languageCode = getLanguageCodeFromUrl(window.location.href);
    if (cachedData) return cachedData;
    try {
        const res = await axiosClient.get(`landingPage/getSuccessStories/${languageCode}`); // Pass the language code in the URL
        const data = res.data;
        data && setCachedData("SuccessStories", data);
        return data;
    } catch (error) {
        throw new Error("Failed to fetch data");
    }
};
export const getCannedQuestions = async () => {
    const cachedData = getCachedData("CannedQuestions");
    const languageCode = getLanguageCodeFromUrl(window.location.href);
    if (cachedData) return cachedData;
    try {
        const res = await axiosClient.get(`Chat/getCannedQuestions/${languageCode}`); // Pass the language code in the URL
        const data = res.data;
        data && setCachedData("CannedQuestions", data);
        return data;
    } catch (error) {
        throw new Error("Failed to fetch data");
    }
};

