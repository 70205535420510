import { Stack, StackItem, mergeStyleSets } from "@fluentui/react";

import { Link, scroller } from 'react-scroll';
import { mergeClasses } from "@fluentui/react-components";
import { FC, useEffect, useRef, useState } from "react";
import "./TabListStyles.scss";
import { commonStyles } from "../CommonStyles/CommonStyles";
import { useTranslation } from "react-i18next";

const styles = mergeStyleSets({
    navbarContainer: {
        position: "static",
        top: 0,
        "@media (max-width: 768px)": {
            display: "none",
        },
        zIndex: 100,
    },
    navbar: {
        width: "100%",
        backgroundColor: "#fff",
        borderBottom: "1px solid  #DCDCDC",
        //  borderRadius: "12px",
        //  boxShadow: "0px 1px 4.8px 0px rgba(0, 0, 0, 0.15)",
    },
    noBorderRadiusClass: {
        borderTopRightRadius: "0px",
        borderTopLeftRadius: "0px",
    },
    fixed :{
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 1000 
}
});

export const sections = [
    { label: "Learn by topic", id: "topic" },
    { label: "Learn by role", id: "role" },
    { label: "Assess your skills", id: "skills" },
    { label: "Success stories", id: "stories" },
];

const PageTabNavigator: FC = () => {
    const [isFixed, setIsFixed] = useState(false);
    const [activeTab, setActiveTab] = useState<string>(sections[0].id);
    const [isAtTop, setIsAtTop] = useState<boolean>(true);
    const { t, i18n } = useTranslation();
    
    const navbarRef = useRef<HTMLDivElement>(null);
    const placeholderRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const handleScroll = () => {
            const navbar = navbarRef.current;
            const placeholder = placeholderRef.current;
            if (navbar && placeholder) {
                const rect = placeholder.getBoundingClientRect();
                if (rect.top <= 0 && window.scrollY > 0) {
                    setIsFixed(true);
                } else {
                    setIsFixed(false);
                }
            }


            if (window.scrollY > 0) {
                setIsAtTop(false);
            } else {
                setIsAtTop(true);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll(".section");

            sections.forEach((section) => {
                const rect = section.getBoundingClientRect();
                if (rect.top <= 500 && rect.bottom >= 90) {
                    setActiveTab(section.id);
                }
            });
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    const handleKeyDown = (event : any, sectionId : any) => {
        if (event.key === 'Enter') {
            scroller.scrollTo(sectionId, {
                duration: 600,
                delay: 0,
                smooth: 'easeInOutQuart',
                offset: -62,
            });
        }
    };

    return (
        <>
            <div ref={placeholderRef} style={{ height: isFixed ? navbarRef.current?.offsetHeight : 'auto' }} />
            <div className={mergeClasses(
                styles.navbarContainer,
                isFixed ? styles.fixed : '',
                isAtTop ? styles.noBorderRadiusClass : ''
            )} id="navbar" ref={navbarRef}>
                <Stack>
                    <StackItem
                        align="center"
                        className={
                            isAtTop
                                ? mergeClasses(styles.navbar, styles.noBorderRadiusClass)
                                : styles.navbar
                        }>
                        <Stack className={commonStyles.container}>
                            <nav className="tab-List">
                                {!!activeTab &&
                                   sections
                                    .filter((section) => {
                                        const translation = t(`NavBar.${section.id}`, 'null')?.trim();
                                        return translation && translation !== 'null';
                                    })
                                    .map((section) => (
                                        <Link
                                            to={section.id}
                                            key={section.id}
                                            offset={-62}
                                            smooth={true}
                                            duration={600}
                                            tabIndex={0}
                                            className={
                                                activeTab === section.id
                                                    ? "nav-item is-active"
                                                    : "nav-item"
                                            }
                                            onKeyDown={(event) => handleKeyDown(event, section.id)}
                                        >
                                            
                                               {t(`NavBar.${section.id}`)} 
                                        </Link>
                                    ))}
                            </nav>
                        </Stack>
                    </StackItem>
                </Stack>
            </div>
        </>
    );
};

export default PageTabNavigator;