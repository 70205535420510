import { mergeStyleSets } from "@fluentui/react";

export const commonStyles = mergeStyleSets({
  container: {
    maxWidth: "1600px",
    width: "100%",
    margin: "0px auto",
    padding: "0px 0px",
    transition: "all 0.3s ease",
    "@media (max-width: 1850px)": {
      padding: "0% 5.5%",
    },
    "@media (max-width: 1150px)": {
    //  padding: "0% 12%",
    },
    "@media (max-width: 768px)": {
      padding: "0% 6%",
    },
    "@media (max-width: 640px)": {
      padding: "0% 3%",
    },
  },

  cardsContainer: {
    margin: "auto",
    width: "100%",
    display: "grid",
    gridAutoRows: "auto",
    gridTemplateColumns: "repeat(4, 330px)",
    justifyContent: "space-between",
    transition: "all 0.3s ease",
    "@media (max-width: 1470px)": {
      gridTemplateColumns: "repeat(4, auto)",
      //gap: "1rem",
    },

    "@media (max-width: 1200px)": {
          gridTemplateColumns: "repeat(2, 335px)",
      },
      "@media (max-width: 900px)": {
          gridTemplateColumns: "repeat(2, 318px)",
      },
    "@media (max-width: 768px)": {
        gridTemplateColumns: "repeat(2,  260px)",
    },
    "@media (max-width: 600px)": {
      gridTemplateColumns: "repeat(1, auto)",
    },
    "@media (max-width: 320px)": {
      display: "flex",
      flexWrap: "wrap",
    },
  },
  heading: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "25px",
  },
  subHeading: {
    padding: "20px 0px 20px 0px",
    lineHeight: "20px",
  },
});
