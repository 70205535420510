import { useEffect } from "react";
import { useUHF } from "../../hooks/useHooks";
import { LoadCss } from "./helperFunctions";
import "./MsUHFStyles.scss";

const MsFooter = (props: any) => {
  const { data } = useUHF();

  useEffect(() => {
    async function parseXmlData(xmlData: any) {
      const tempHtml =
        xmlData.getElementsByTagName("footerHtml")[0].childNodes[0];
      const tempCss =
        xmlData.getElementsByTagName("cssIncludes")[0].childNodes[0];
      const tempJs =
        xmlData.getElementsByTagName("javascriptIncludes")[0].childNodes[0];

      const footerSection = document.getElementById("footer-container");
      footerSection && (await LoadCss(tempCss.wholeText, footerSection));

      const srcRegex = /<script src="([^"]*)"><\/script>/;
      const match = tempJs.wholeText.match(srcRegex);
      if (match) {
        const script = document.createElement("script");
        script.src = match[1];
        script.type = "text/javascript";
        if (footerSection) {
          footerSection.appendChild(script);
        }
      }

      const footer = document.getElementById("footer");
      if (footer) footer.innerHTML = tempHtml.wholeText;
    }

    data && parseXmlData(data);
  }, [data]);

  return (
    <div id="footer-container" className="footer">
          <div id="footer"></div>
          <div id="bot" className="bot-div"></div>
    </div>
  );
};

export default MsFooter;
