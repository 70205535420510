import { Icon, IconButton, Stack, Text } from "@fluentui/react";
import { DrawerHeader, makeStyles } from "@fluentui/react-components";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface HeaderProp {
  togglePanel: () => void;
}

const useStyles = makeStyles({
    headerContainer: {
        paddingTop: "15px",
        paddingBottom: "15px ",
        borderBottom: "1px solid #DDDDDD",
        boxShadow: "0px 0px 4px 0px #00000040",

    },
  cancelIcon: {
    size: "16px",
    color: "#000000",
  },
  robotIcon: {
    fontSize: "40px",
    fontWeight: 400,
    lineHeight: "40px",
    textAlign: "center",
    color: "#0078D7",
  },
  heading: {
    fontSize: "24px",
    fontWeight: "600",
    },

    buttonText: {
        color: "#1A1A1A",
        fontSize: "18px",
        marginLeft: "20px",
        marginRight: "20px",
        lineHeight: "24px",
        fontWeight: "700 !important"
    },
    image: {
        position: "relative",
        top:"3px"
    }
});

const CancelIcon: FC<HeaderProp> = ({ togglePanel }) => {
    const styles = useStyles();
    const images = (require as any).context("../../assets", true);
    const clipicon = "./Clippath.png";
    const clippath = images(clipicon);
    const { t, i18n } = useTranslation();

  return (
    <>
          <DrawerHeader className={styles.headerContainer}>
              <Stack horizontal horizontalAlign="end" style={{ justifyContent: "space-between" }}>
                  <Stack horizontal >
                     <span>
                          <img
                              src={clippath}
                              alt="logo"
                              className={styles.image }
                          />
                      </span>
                      <span className={styles.buttonText}>
                      {t('ChatBot.Navigator')}
                      </span>          
                  </Stack>
                  <IconButton
                      iconProps={{ iconName: "Cancel" }}
                      ariaLabel="Close"
                      onClick={togglePanel}
                      className={styles.cancelIcon}
                  />
        </Stack>
      </DrawerHeader>
    </>
  );
};

export default CancelIcon;
