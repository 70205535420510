import { IDocumentCardPreviewProps, ImageFit } from "@fluentui/react";
import { LearningType } from "../../Types/LearningType";

export const getCardPreviewProps = (cardData: LearningType) => {
    const images = (require as any).context("../../assets", true);
    if (cardData.imageUrl === "") {
        const defaultImageurl = "./topic.png";
        cardData.imageUrl = images(defaultImageurl);
    }

  const previewProps: IDocumentCardPreviewProps = {
    previewImages: [
      {
       // name: cardData.contentType,
            linkProps: {
                href: cardData.learnMoreUrl,
          target: "_blank",
            },
            previewImageSrc: cardData.imageUrl,
        iconSrc: "",
        imageFit: ImageFit.cover,
        height: 196,
      },
    ],
  };
  return previewProps;
};
