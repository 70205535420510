import { Stack, mergeStyleSets, Text, DefaultButton } from "@fluentui/react";
import React from "react";
import { commonStyles } from "../CommonStyles/CommonStyles";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


const Skills: React.FC = () => {
    const images = (require as any).context("../../assets", true);
    const skillsimg = images("./skills.png");
    const skillspeople = images("./skillpeople.png");
    const { t, i18n } = useTranslation();

    const styles = mergeStyleSets({
        skillsContainer: {
            backgroundColor: "#fff",
            padding: "50px 0px",
            "@media (max-width: 768px)": {
                marginTop: "0px",
            },
            "@media (max-width: 1024px)": {
                padding: "48px 0px",
            },
        },
        link: {
            color: "#0074D3", textDecoration: "none", fontSize: "16px"
        },
        skillHeading: {
            fontSize: "32px",
            fontWeight: 600,
            lineHeight: "40px",
            textAlign: "left"

        },
        skillDescription: {
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "21.28px",
            textAlign: "left",
            marginTop: "20px",
            "@media (min-width:769px) and (max-width: 940px)": {
                marginTop: "10px",
            },
        },
        details: {
            border: "1px solid #E6F2FB",
            boxShadow: "0px 2px 4px 0px #00000024",
            backgroundColor: "white",
            padding: "10px",
            borderRadius: "24px",
            "@media (max-width: 768px)": {
                display: "block"
            },
            "@media (min-width: 769px)": {
                display: "flex"
            },
        },
        skillsButton: {
            height: "32px",
            border: "none",
            backgroundColor: "#0078D4",
            color: "white",
            borderRadius: "8px",
            marginTop: "20px",
            padding: "20px",
            "& span": {
                fontSize: "15px", // add font-size for span
            },
            "@media (min-width:769px) and (max-width: 940px)": {
                marginTop: "10px",
            },
            ":focus::after": {
                borderRadius: "8px !important",
                border:"2px solid black !important"
            },
        },
        skillImg: {
            "@media (max-width: 1400px)": {
                width: "500px !important"
            },
            "@media (max-width: 768px)": {
                display: "none"
            },
            "@media (min-width: 769px)": {
                display: "flex"
            },
            "@media (min-width:769px) and (max-width: 940px)": {
                width: "350px !important",
                padding: "0rem 0rem 0rem 0rem",
            },
        },
        skillImgMob: {
            "@media (max-width: 768px)": {
                display: "flex"
            },
            "@media (min-width: 769px)": {
                display: "none"
            },
            "@media (min-width:769px) and (max-width: 940px)": {
                width: "350px !important",
                padding: "0rem 0rem 0rem 0rem",
            },
        },
        detailsDiv: {
            padding: "6rem 2rem 0 2rem",
            "@media (min-width:940px) and (max-width: 1400px)": {
                padding: "2.5rem 2rem 2.5rem 2rem !important",
            },
            "@media (min-width:769px) and (max-width: 940px)": {
                padding: "0rem 2rem 0rem 2rem !important",
            },
            "@media (max-width: 768px)": {
                padding: "3rem 0rem 3rem 0rem",
            },
        }
    });

    const heading = t('Skills.heading','null')?.trim();
    const description = t('Skills.description','null')?.trim();
    const skillsLink = t('Skills.skillsLink','null')?.trim();
    const assessment = t('Skills.assessment','null')?.trim();

    // Check for empty, null, or "null" values
    if (!heading || heading === "null" || !description || description === "null" || !skillsLink || skillsLink === "null" || !assessment || assessment === "null") {
        return null;
    }
 
    return (
        <Stack className={styles.skillsContainer} style={{
            background: ` url(${skillsimg}) no-repeat center `,
            backgroundSize: "cover",
        }}>
            <Stack className={commonStyles.container}>
                <Stack horizontal className={styles.details}>
                    <Stack>
                        <img src={skillspeople} alt="skills" className={styles.skillImgMob} />
                    </Stack>
                    <Stack className={styles.detailsDiv}>
                        <Text variant="large" className={styles.skillHeading}>
                            {t('Skills.heading')}
                        </Text>
                        <Text variant="medium" className={styles.skillDescription}>
                            {t('Skills.description')}
                        </Text>
                        <Link
                            to={t('Skills.skillsLink')}
                            target="_blank"
                            tabIndex={-1}
                        >
                            <DefaultButton className={styles.skillsButton} >
                            {t('Skills.assessment')}
                            </DefaultButton>
                        </Link>
                    </Stack>
                    <Stack >
                        <img src={skillspeople} alt="skills" className={styles.skillImg} />
                    </Stack>
                </Stack>
            </Stack>
        </Stack>

    );

};

export default Skills;
